/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import React, { useCallback, useEffect, useState } from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import Favourite from '../components/Favourite/Favourite';
import Layout from '../components/Layout';
import { Title } from '../components/Title';
import useFavourites from '../hooks/common/useFavourites';
import {
  IGeneralPractice,
  IOrganisation,
} from '../hooks/promises/getGeneralPractices';
import useGetGeneralPractices from '../hooks/useGetGeneralPractices';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../components/Spinner/Spinner';
import useGetPrimaryCareNetworks from '../hooks/useGetPrimaryCareNetworks';
import LoadingPage from './LoadingPage';
import ErrorPage from './ErrorPage';
import { RequireAuth } from '../components/AuthWrapper/RequireAuth';
import setTitle from '../utils/title';
import { Dropdown } from '../components/Dropdown/Dropdown';

const toastOptions: ToastOptions = {
  position: 'bottom-right',
};

function FavouritesPageUnsecured() {
  const [practiceSearchQuery, setPracticeSearchQuery] = useState('');
  const [pcnSearchQuery, setPcnSearchQuery] = useState('');
  const [selectedPractice, setSelectedPractice] =
    useState<IGeneralPractice | null>(null);
  const [selectedPCN, setSelectedPCN] = useState<IOrganisation | null>(null);

  const [actionProcessing, setActionProcessing] = useState(false);

  const {
    favourites: loadedFavourites,
    addFavourite: sendAddFavouriteRequest,
    removeFavourite: sendRemoveFavouriteRequest,
  } = useFavourites();
  const [favourites, setFavourites] = useState<IGeneralPractice[]>([]);

  useEffect(() => {
    if (loadedFavourites)
      setFavourites(loadedFavourites.map((x) => x.organisation));
  }, [loadedFavourites]);

  const { practices: loadedPractices, error: errorPractices } =
    useGetGeneralPractices(
      practiceSearchQuery,
      selectedPCN?.organisationId ?? -1
    );
  const { pcns: loadedPcns, error: errorPcns } =
    useGetPrimaryCareNetworks(pcnSearchQuery);

  const addFavourite = useCallback(
    (practice: IGeneralPractice | null) => {
      if (!practice) return;
      if (
        favourites.filter((x) => x.organisationId === practice.organisationId)
          .length > 0
      )
        return;

      setActionProcessing(true);
      sendAddFavouriteRequest(practice.organisationId)
        .then(() => {
          setFavourites([...favourites, practice]);
          toast.success('Favourite successfully added', toastOptions);
          setSelectedPractice(null);
        })
        .catch(() => toast.error('Error adding favourite', toastOptions))
        .finally(() => setActionProcessing(false));
    },
    [favourites]
  );

  const deleteFavourite = useCallback(
    (organisationId: number) => {
      const remainingFavourites = favourites.filter(
        (favourite) => organisationId !== favourite.organisationId
      );

      setActionProcessing(true);
      sendRemoveFavouriteRequest(organisationId)
        .then(() => {
          setFavourites(remainingFavourites);
          toast.success('Favourite successfully removed', toastOptions);
        })
        .catch(() => toast.error('Error removing favourite', toastOptions))
        .finally(() => setActionProcessing(false));
    },
    [favourites]
  );

  const onPCNSelected = (pcn: IOrganisation | null) => {
    setSelectedPCN(pcn);
  };

  if (errorPcns || errorPractices) return <ErrorPage layout />;

  if (!loadedPractices || !loadedPcns) return <LoadingPage layout />;

  return (
    <Layout>
      <Title text="Set Favourite GP Practices" />
      <div className="w-[600px] mx-auto mt-8">
        <Dropdown
          items={loadedPcns}
          getItemId={(x) => x.organisationId.toString()}
          getItemValue={(x) => x.organisationDescription}
          setSelectedItem={onPCNSelected}
          selectedItem={selectedPCN}
          searchable
          onSearch={setPcnSearchQuery}
          searchQuery={pcnSearchQuery}
          label="Primary Care Networks"
          className="mb-4"
        />
        <Dropdown
          items={loadedPractices}
          getItemId={(x) => x.organisationId.toString()}
          getItemValue={(x) => x.organisationDescription}
          setSelectedItem={addFavourite}
          selectedItem={selectedPractice}
          onSearch={setPracticeSearchQuery}
          label="GP Practices"
          className="mb-4"
        />
        {favourites
          ? favourites.map((favourite) => (
              <Favourite
                key={favourite.organisationId}
                displayNameId={favourite.organisationId}
                displayName={favourite.organisationDescription}
                deleteFavourite={deleteFavourite}
              />
            ))
          : null}
        {actionProcessing && (
          <div className="flex justify-center mt-16">
            <Spinner />
          </div>
        )}
      </div>
      <ToastContainer />
    </Layout>
  );
}

function FavouritesPage() {
  setTitle('Favourites');
  return (
    <RequireAuth>
      <FavouritesPageUnsecured />
    </RequireAuth>
  );
}

export default FavouritesPage;
