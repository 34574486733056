import moment from 'moment';
import { getApi, postApi, putApi } from '../../utils/api';

export interface IGetDailyCapacity {
  capacityCount: number;
  capacityDate: string;
  createdDate?: string;
  lastUpdatedDate?: string;
}

export interface IDailyCapacity {
  capacityCount: number;
  capacityDate: string;
  lastUpdatedDate?: string;
}

export const getDailyCapacity = () =>
  getApi<IGetDailyCapacity[]>('DailyCapacity');

export const getDailyCapacityByDate = (capacityDate: string) =>
  getApi<IDailyCapacity>(`DailyCapacity/${capacityDate}`);

export const putDailyCapacity = (capacityCount: number, capacityDate: string) =>
  putApi<IDailyCapacity, IDailyCapacity>('DailyCapacity', {
    capacityCount,
    capacityDate: capacityDate + moment().format('THH:mm:ssZ'),
    lastUpdatedDate: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
  });

export const postDailyCapacity = (
  capacityCount: number,
  capacityDate: string
) =>
  postApi<IDailyCapacity, IDailyCapacity>('DailyCapacity', {
    capacityCount,
    capacityDate,
  });
