import * as React from 'react';
import { useEffect, useState } from 'react';
import { IConsultationOutcome } from '../../hooks/promises/getConsultationOutcomes';
import TextField from '../TextField/TextField';

export interface ICheckboxProps {
  item: IConsultationOutcome;
  onClick?: (val: boolean) => void;
  startChecked: boolean;
  disabled: boolean;
  onInputText?: (val: string) => void;
  defaultText?: string;
}

export function Checkbox({
  item,
  onClick,
  disabled,
  onInputText,
  startChecked,
  defaultText,
}: ICheckboxProps) {
  const [checked, setChecked] = useState<boolean>(false);
  const [text, setText] = useState<string>(defaultText ?? '');
  useEffect(() => {
    if (onClick) onClick(checked);
  }, [checked]);
  useEffect(() => setChecked(startChecked), []);

  return (
    <div className={`flex flex-row items-center ${onInputText ? 'my-2' : ''}`}>
      <input
        type="checkbox"
        id={item.consultationOutcomeDescription}
        checked={checked}
        disabled={disabled && !checked}
        onChange={() => setChecked((x: boolean) => !x)}
        className="mr-2 cursor-pointer "
      />
      {onInputText ? (
        <TextField
          text={text}
          setText={(x) => {
            setText(x);
            onInputText(x);
            setChecked(true);
          }}
          disabled={disabled && !checked}
          className="w-full"
          size="xs"
        />
      ) : (
        <p
          className={`cursor-pointer select-none ${
            checked ? 'font-semibold text-primary-500' : ''
          }`}
          onClickCapture={() => setChecked((c) => !(disabled || c))}
        >
          {item.consultationOutcomeDescriptionShort}
        </p>
      )}
    </div>
  );
}
