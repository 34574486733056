import React from 'react';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IFavouriteProps {
  displayNameId: number;
  displayName: string;
  deleteFavourite: (displayNameId: number) => void;
}

function Favourite({
  displayNameId,
  displayName,
  deleteFavourite,
}: IFavouriteProps) {
  return (
    <div className="flex flex-row justify-between m-2 bg-white border-[1px] rounded-md shadow-md">
      <p className="px-4 py-2 text-lg font-semibold">{displayName}</p>
      <button
        type="button"
        data-testid="delete-btn"
        className="p-2 px-4 transition rounded-r-md hover:bg-red-600 hover:text-white"
        onClick={() => deleteFavourite(displayNameId)}
        onKeyDown={() => deleteFavourite(displayNameId)}
      >
        <FontAwesomeIcon icon={faX} />
      </button>
    </div>
  );
}

export default Favourite;
