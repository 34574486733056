import { IMetadata } from '../../interfaces/common';
import { getApiCached } from '../../utils/api';

export interface IConsultationOutcomeGroup {
  consultationOutcomeGroupId: number;
  consultationOutcomeGroupDescription: string;
}

// api/Lookup/ConsultationOutcomes
export interface IConsultationOutcome extends IMetadata {
  consultationOutcomeId: number;
  consultationOutcomeDescription: string;
  consultationOutcomeDescriptionShort: string;
  consultationOutcomeGroup: IConsultationOutcomeGroup;
  displayOrder: number;
}

export const getConsultationOutcomes = () =>
  getApiCached<IConsultationOutcome[]>('lookup/consultationoutcomes');
