/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useClickOutside } from '@mantine/hooks';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

interface IMenuItemProps {
  text: string;
  to: string;
}

function MenuItem({ text, to }: IMenuItemProps) {
  return (
    <span className="text-lg font-semibold text-white transition duration-300 group">
      <NavLink
        to={to}
        className={(i) =>
          i.isActive ? 'underline decoration-2 underline-offset-[9px]' : ''
        }
      >
        {text}
        <span className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-white" />
      </NavLink>
    </span>
  );
}

interface IMenuDropdownProps {
  text: string;
  options: IMenuDropdownOptions[];
}

interface IMenuDropdownOptions {
  text: string;
  to: string;
}

function MenuDropdown({ text, options }: IMenuDropdownProps) {
  const [visible, setVisible] = React.useState(false);
  const [visibleLock, setVisibleLock] = React.useState(false);

  const ref = useClickOutside(() => setVisibleLock(false));

  const [active, setActive] = React.useState(
    options.map((x) => x.to).filter((x) => x === location.pathname).length > 0
  );

  return (
    <span
      className={`relative text-lg font-semibold text-white transition duration-300 group ${
        active ? 'underline decoration-2 underline-offset-[9px]' : ''
      }`}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      onClickCapture={() => setVisibleLock((x) => !x)}
    >
      <span className="cursor-pointer">{text}</span>
      <div
        className={`absolute pt-2 ${
          visible || visibleLock ? 'visible' : ' invisible'
        }`}
      >
        <div
          ref={ref}
          className="py-1 text-center text-black bg-white rounded-md shadow"
        >
          {options.map((x) => (
            <NavLink
              key={x.to}
              to={x.to}
              className={(i) => (i.isActive ? 'text-primary-500' : '')}
            >
              <div className="px-10 py-2 mb-1 hover:bg-gray-300">{x.text}</div>
            </NavLink>
          ))}
        </div>
      </div>
    </span>
  );
}

function HeaderNavMenu() {
  return (
    <div className="flex justify-between">
      <MenuItem text="Register Appointment" to="/register" />
      <MenuItem text="View Appointments" to="/view" />
      <MenuItem text="Favourites" to="/favourites" />
      <MenuItem text="Capacity" to="/edit-capacity" />
      <MenuItem text="Reporting" to="/reporting" />
    </div>
  );
}

export default HeaderNavMenu;
