import { useMemo } from 'react';
import {
  getDailyCapacityByDate,
  putDailyCapacity,
} from '../promises/getDailyCapacity';
import useApiResult from './useApiResult';

function useDailyCapacity(capacityDate: string) {
  const capacityResults = useMemo(
    () => getDailyCapacityByDate(capacityDate),
    [capacityDate]
  );
  const { loading, error, result } = useApiResult(capacityResults);

  async function addDailyCapacity(capacityCount: number) {
    try {
      await putDailyCapacity(capacityCount, capacityDate);
    } catch (e) {
      console.error(e);
    }
  }

  return { loading, error, dailyCapacity: result, addDailyCapacity };
}

export default useDailyCapacity;
