import { IPublicClientApplication } from '@azure/msal-browser/dist/app/IPublicClientApplication';
import { loginRequest } from '../authConfig';
import { publicClientApplication } from '../components/AuthWrapper/AuthWrapper';

export async function retrieveAccessToken() {
  const account = publicClientApplication.getActiveAccount();

  if (account === null)
    throw new Error(
      'Access token cannot be acquired as there is no active account.'
    );

  const request = {
    scopes: loginRequest.scopes,
    account,
  };

  try {
    const response = await publicClientApplication.acquireTokenSilent(request);
    return response.idToken;
  } catch (e) {
    console.error(e);
    publicClientApplication.acquireTokenRedirect(request);
    throw e;
  }
}

export const storeAccessToken = (token: string) =>
  localStorage.setItem('accessToken', token);

export const clearAccessToken = () => localStorage.removeItem('accessToken');

export async function login() {
  try {
    await publicClientApplication.loginRedirect(loginRequest);
  } catch (e) {
    console.error(e);
  }
}

export async function logout() {
  try {
    const account = publicClientApplication.getActiveAccount();
    clearAccessToken();
    publicClientApplication.setActiveAccount(null);
    localStorage.clear();
    await publicClientApplication.logoutRedirect({ account });
  } catch (e) {
    console.error(e);
  }
}

export enum UserRole {
  PhysioUser = 'User',
  PhysioReporter = 'Reporter',
}

export const hasRole = (
  instance: IPublicClientApplication,
  role: UserRole
): boolean => {
  const account = instance.getActiveAccount();
  if (!account) return false;

  const claims = account.idTokenClaims;
  if (!claims) return false;

  const roles = claims.roles ?? [];

  return roles.indexOf(role) !== -1;
};

export async function acquireAccessToken() {
  const account = publicClientApplication.getActiveAccount();

  if (account === null)
    throw new Error(
      'Access token cannot be acquired as there is no active account.'
    );

  const request = {
    scopes: loginRequest.scopes,
    account,
  };

  try {
    publicClientApplication.acquireTokenRedirect(request);
    return '';
  } catch {
    try {
      await login();
      return '';
    } catch {
      throw new Error('Unable to acquire access token');
    }
  }
}
