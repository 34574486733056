import { IMetadata } from '../../interfaces/common';
import { getApiCached } from '../../utils/api';

// api/Lookup/ConsultationTypes
export interface IConsultationType extends IMetadata {
  consultationTypeId: number;
  consultationTypeDescription: string;
}

export const getConsultationTypes = () =>
  getApiCached<IConsultationType[]>('lookup/consultationtypes');
