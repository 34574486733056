import { getApi } from '../../utils/api';

export interface IUser {
  forename: string;
  surname: string;
  azureUsername?: string;
  createdDate?: string;
}

export const getUser = () => getApi<IUser>('Users/AuthorisedUser');
