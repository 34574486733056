import { getApi } from '../../utils/api';

export interface IOrganisation {
  organisationId: number;
  organisationDescription: string;
}

export interface IGeneralPractice extends IOrganisation {
  primaryCareNetwork: IOrganisation;
  clinicalCommissioningGroup: IOrganisation;
}

export const getGeneralPractices = (query: string, pcnId: number) =>
  getApi<IGeneralPractice[]>(
    `organisation/generalpractices?filterText=${query}&primaryCareNetworkId=${pcnId}&maxRows=20`
  );
export const getPrimaryCareNetworks = (query: string) =>
  getApi<IOrganisation[]>(
    `organisation/primarycarenetworks?filterText=${query}&maxRows=20`
  );
