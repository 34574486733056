import * as React from 'react';
import Layout from '../components/Layout';
import Spinner from '../components/Spinner/Spinner';

interface ILoadingPageProps {
  layout?: boolean;
}

export default function LoadingPage({ layout }: ILoadingPageProps) {
  const page = (
    <div className="flex justify-center mx-auto mt-64">
      <div>
        <Spinner />
      </div>
    </div>
  );

  if (layout) return <Layout>{page}</Layout>;

  return page;
}
