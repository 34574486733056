import React from 'react';
import Header from './Header/Header';

interface ILayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: ILayoutProps) {
  return (
    <div className="">
      <Header />
      <div className="pt-16 mx-auto lg:px-[15%] px-[5%]">{children}</div>
    </div>
  );
}
