export const msalConfig = {
  auth: {
    // DEV 52dc3980-2a1d-4722-8c7a-ee7fb810c336

    // UAT aeeea5d6-b079-4aec-a3c0-c91024fbead3

    clientId: process.env.REACT_APP_AUTH_CLIENTID ?? '',

    authority: process.env.REACT_APP_AUTH_AUTHORITY ?? '',

    redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL ?? '',
  },

  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored

    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.

// DEV api://026622ae-7d3e-4cb6-8b52-7eddd5bcbda6/access_as_user

// UAT api://7e469141-796a-426c-958f-acca828cf7a0/access_as_user

export const loginRequest = {
  scopes: [process.env.REACT_APP_LOGIN_REQUEST_SCOPE ?? ''],

  redirectStartPage: '/gateway',

  prompt: 'select_account',
};
