import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { retrieveAccessToken } from './security';

const axiosApiInstance = axios.create();

export interface IResult<T> {
  content: T | null;
  message: string;
  status: number;
}

export function getApi<T>(
  endpoint: string,
  responseType?: ResponseType | undefined
): Promise<AxiosResponse<IResult<T>, unknown>> {
  return axiosApiInstance.get<IResult<T>, any>(
    `${process.env.REACT_APP_API_URL}${endpoint}`,
    {
      responseType,
    }
  );
}

export function getApiCached<T>(
  endpoint: string
): Promise<AxiosResponse<IResult<T>, unknown>> {
  const localStorageData = localStorage.getItem(endpoint);

  if (localStorageData) {
    const cachedData = JSON.parse(localStorageData) as T;
    return new Promise<AxiosResponse<IResult<T>, unknown>>((resolve) => {
      const fakeResult = {
        content: cachedData,
        message: '',
        status: 200,
      } as IResult<T>;
      const fakeResponse: AxiosResponse<IResult<T>, unknown> = {
        data: fakeResult,
        status: 200,
        statusText: '',
        config: {},
        headers: {},
      };
      resolve(fakeResponse);
    });
  }

  return new Promise<AxiosResponse<IResult<T>, unknown>>((resolve, reject) => {
    const call = axiosApiInstance.get<IResult<T>, any>(
      `${process.env.REACT_APP_API_URL}${endpoint}`
    );

    call
      .then((response) => {
        localStorage.setItem(endpoint, JSON.stringify(response.data.content));
        resolve(call);
      })
      .catch((e) => reject(e));
  });
}

export { axiosApiInstance };

export function postApi<T, V>(
  endpoint: string,
  payload: V | null
): Promise<AxiosResponse<IResult<T>, unknown>> {
  console.log(payload);
  return axiosApiInstance.post<IResult<T>>(
    `${process.env.REACT_APP_API_URL}${endpoint}`,
    payload
  );
}
export function putApi<T, V>(
  endpoint: string,
  payload: V | null
): Promise<AxiosResponse<IResult<T>, unknown>> {
  console.log(payload);
  return axiosApiInstance.put<IResult<T>>(
    `${process.env.REACT_APP_API_URL}${endpoint}`,
    payload
  );
}

export function deleteApi<T>(
  endpoint: string
): Promise<AxiosResponse<IResult<T>, unknown>> {
  return axiosApiInstance.delete<IResult<T>>(
    `${process.env.REACT_APP_API_URL}${endpoint}`
  );
}

export function mapResult<T>(response: AxiosResponse<IResult<T>, unknown>): T {
  if (response.status !== 200) {
    throw new Error(
      `Received status code ${response.status} when calling ${response.request?.responseURL}`
    );
  }

  if (response.data.content === null) {
    throw new Error('Content was unexpectedly null');
  }

  return response.data.content;
}

axiosApiInstance.interceptors.request.use(
  async (c: AxiosRequestConfig<unknown>) => {
    const config = c;
    const accessToken = await retrieveAccessToken();

    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': process.env.REACT_APP_ORIGIN_URL ?? '',
      };
      config.withCredentials = true;
    }

    return config;
  },
  (error) => Promise.reject(error)
);
