import { DatePicker } from '@mantine/dates';
import 'react-datepicker/dist/react-datepicker.css';

export interface IDateFieldProps {
  date: Date | null;
  setDate: (val: Date) => void;
  placeholder?: string;
  className?: string;
  maxDate?: Date;
  minDate?: Date;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export function DateField({
  date,
  setDate,
  placeholder,
  className,
  maxDate,
  minDate,
  size,
}: IDateFieldProps) {
  return (
    <DatePicker
      placeholder={placeholder}
      minDate={minDate}
      maxDate={maxDate}
      value={date}
      onChange={setDate}
      inputFormat="DD/MM/YYYY"
      clearable={false}
      size={size ?? 'lg'}
      allowLevelChange={false}
      className={className}
    />
  );
}
