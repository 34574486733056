import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button/Button';

export default function RegisterAppointmentSending() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center mx-auto mt-32">
      <div className="flex flex-col items-center">
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-primary-500"
          fontSize={96}
        />
        <h1 className="mt-8 text-2xl text-center text-subtitle">
          Appointment was successfully recorded.
        </h1>
        <div className="mt-32">
          <Button
            variant="outline"
            text="View appointments"
            className="mr-6"
            onClick={() => navigate('/view')}
          />
          <Button
            variant="filled"
            text="Register a new appointment"
            onClick={() => navigate(0)}
          />
        </div>
      </div>
    </div>
  );
}
