/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import {
  getConsultationOutcomes,
  IConsultationOutcome,
} from '../promises/getConsultationOutcomes';
import {
  getConsultationTypes,
  IConsultationType,
} from '../promises/getConsultationTypes';
import {
  getModeOfContacts,
  IModeOfContact,
} from '../promises/getModeOfContacts';
import {
  getReferralSources,
  IReferralSource,
} from '../promises/getReferralSources';
import useApiResult from './useApiResult';

export interface ILookupTypes {
  consultationOutcomes: IConsultationOutcome[];
  consultationTypes: IConsultationType[];
  modeOfContacts: IModeOfContact[];
  referralSources: IReferralSource[];
}

export function useGetLookupTypes() {
  const [result, setResult] = useState<ILookupTypes>({
    consultationOutcomes: [],
    consultationTypes: [],
    modeOfContacts: [],
    referralSources: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const consultationOutcomesResult = useMemo(
    () => getConsultationOutcomes(),
    []
  );
  const {
    loading: consultationOutcomesLoading,
    error: consultationOutcomesError,
    result: consultationOutcomesData,
  } = useApiResult(consultationOutcomesResult);

  const consultationTypesResult = useMemo(() => getConsultationTypes(), []);
  const {
    loading: consultationTypesLoading,
    error: consultationTypesError,
    result: consultationTypesData,
  } = useApiResult(consultationTypesResult);

  const modeOfContactsResult = useMemo(() => getModeOfContacts(), []);
  const {
    loading: modeOfContactsLoading,
    error: modeOfContactsError,
    result: modeOfContactsData,
  } = useApiResult(modeOfContactsResult);

  const referralSourcesResult = useMemo(() => getReferralSources(), []);
  const {
    loading: referralSourcesLoading,
    error: referralSourcesError,
    result: referralSourcesData,
  } = useApiResult(referralSourcesResult);

  React.useEffect(() => {
    setLoading(
      consultationOutcomesLoading ||
        consultationTypesLoading ||
        modeOfContactsLoading ||
        referralSourcesLoading
    );

    setResult({
      consultationOutcomes: consultationOutcomesData ?? [],
      consultationTypes: consultationTypesData ?? [],
      modeOfContacts: modeOfContactsData ?? [],
      referralSources: referralSourcesData ?? [],
    });
  }, [
    consultationOutcomesLoading,
    consultationTypesLoading,
    modeOfContactsLoading,
    referralSourcesLoading,
  ]);

  React.useEffect(() => {
    setError(
      consultationOutcomesError ||
        consultationTypesError ||
        modeOfContactsError ||
        referralSourcesError
    );
  }, [
    consultationOutcomesError,
    consultationTypesError,
    modeOfContactsError,
    referralSourcesError,
  ]);

  return { loading, error, result };
}
