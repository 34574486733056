import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { IResult, mapResult } from '../../utils/api';

export default function useApiResult<T>(
  response: Promise<AxiosResponse<IResult<T>, unknown>>
) {
  const [result, setResult] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const handleResponse = async () => {
    try {
      setError(false);
      const responseResult = await response;
      const data = mapResult<T>(responseResult);
      setResult(data);
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const doHandleReponse = async () => {
      await handleResponse();
    };

    doHandleReponse();
  }, [response]);

  return { result, loading, error };
}
