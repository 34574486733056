import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';

import { Button } from '../../components/Button/Button';
import { CheckboxList } from '../../components/Checkbox/CheckboxList';
import { DateField } from '../../components/DateField/DateField';
import { Dropdown } from '../../components/Dropdown/Dropdown';
import TextField from '../../components/TextField/TextField';
import useFavourites from '../../hooks/common/useFavourites';
import { useGetLookupTypes } from '../../hooks/common/useGetLookupTypes';
import { IGeneralPractice } from '../../hooks/promises/getGeneralPractices';
import {
  ICreateAppointmentBuilder,
  ICreateAppointmentRequestValidationResult,
} from '../../hooks/useCreateAppointmentRequest';
import ErrorPage from '../ErrorPage';
import LoadingPage from '../LoadingPage';

interface IRegisterAppointmentFormProps {
  builder: ICreateAppointmentBuilder;
  onValidateSuccess: () => void;
  validateRequest: () => ICreateAppointmentRequestValidationResult;
}

export default function RegisterAppointmentForm({
  builder,
  onValidateSuccess,
  validateRequest,
}: IRegisterAppointmentFormProps) {
  const { instance } = useMsal();
  const { loading, error, result: lookupTypes } = useGetLookupTypes();
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const {
    favourites,
    error: favouritesError,
    loading: loadingFavourites,
  } = useFavourites();

  const [favouritePractices, setFavouritePractices] = useState<
    IGeneralPractice[]
  >([]);

  useEffect(
    () => setFavouritePractices(favourites?.map((x) => x.organisation) ?? []),
    [favourites]
  );

  useEffect(
    () => builder.setFcpName(instance.getActiveAccount()?.name ?? ''),
    []
  );

  if (loading || loadingFavourites) return <LoadingPage />;
  if (error || favouritesError) return <ErrorPage />;

  const onReviewClick = () => {
    const validationResult = validateRequest();

    if (validationResult.success) onValidateSuccess();
    else {
      setValidationErrors(validationResult.validationMessages);
    }
  };

  const onPracticeSelected = (practice: IGeneralPractice | null) => {
    builder.setOrganisation(practice);
    builder.setPcnName(
      practice ? practice.primaryCareNetwork.organisationDescription : ''
    );
    builder.setIcsCcgName(
      practice
        ? practice.clinicalCommissioningGroup.organisationDescription
        : ''
    );
  };

  return (
    <div className="pb-4">
      <div className="grid grid-flow-col grid-cols-12 gap-4">
        <div className="col-span-6 col-start-1 lg:col-span-3">
          <DateField
            date={builder.dateOfConsultation}
            setDate={builder.setDateOfConsultation}
            placeholder="Date of consultation"
            className="w-full"
            maxDate={new Date()}
          />
        </div>
        <div className="col-span-6 col-start-7 lg:col-span-4 lg:col-start-4">
          <TextField text={builder.fcpName} disabled />
        </div>
        <div className="flex flex-row items-center justify-center col-span-4 col-start-2 lg:col-span-2 lg:col-start-8">
          <div className="flex flex-row items-center justify-center">
            <input
              type="checkbox"
              name="didnotattend"
              className="w-4 h-4 mr-2 cursor-pointer"
              checked={builder.didNotAttend}
              onChange={() => builder.setDidNotAttend((x) => !x)}
            />
            <p
              className="cursor-pointer select-none"
              onClickCapture={() => builder.setDidNotAttend((x) => !x)}
            >
              Did not attend
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center col-span-4 col-start-8 lg:col-span-3 lg:col-start-10">
          <div className="flex flex-row items-center justify-center">
            <input
              type="checkbox"
              name="doubleappointment"
              className="w-4 h-4 mr-2 cursor-pointer"
              checked={builder.doubleAppointment}
              onChange={() => builder.setDoubleAppointment((x) => !x)}
            />
            <p
              className="cursor-pointer select-none"
              onClickCapture={() => builder.setDoubleAppointment((x) => !x)}
            >
              Double appointment?
            </p>
          </div>
        </div>
        <div className="col-span-12 col-start-1 lg:col-span-4">
          <div className="flex flex-row items-center justify-center">
            <Dropdown
              items={favouritePractices}
              getItemId={(x) => x.organisationId.toString()}
              getItemValue={(x) => x.organisationDescription}
              setSelectedItem={onPracticeSelected}
              selectedItem={builder.organisation}
              label="Select GP Practice"
              searchable
            />
          </div>
        </div>
        <div className="col-span-6 col-start-1 lg:col-span-4 lg:col-start-5">
          <div className="flex flex-row items-center justify-center">
            <TextField
              text={builder.pcnName}
              placeholderText="PCN Name"
              disabled
              className="w-full"
            />
          </div>
        </div>
        <div className="col-span-6 col-start-7 lg:col-span-4 lg:col-start-9">
          <div className="flex flex-row items-center justify-center">
            <TextField
              text={builder.icsCcgName}
              placeholderText="ICB Name"
              disabled
              className="w-full"
            />
          </div>
        </div>
        <div className="col-span-6 col-start-1 lg:col-span-4">
          <div className="flex flex-row items-center justify-center">
            <Dropdown
              items={lookupTypes.referralSources}
              getItemId={(x) => x.referralSourceId.toString()}
              getItemValue={(x) => x.referralSourceDescription}
              setSelectedItem={builder.setReferralSource}
              selectedItem={builder.referralSource}
              label="Referral source"
              className="w-full"
            />
          </div>
        </div>
        <div className="col-span-6 col-start-7 lg:col-span-4 lg:col-start-5">
          <div className="flex flex-row items-center justify-center">
            <Dropdown
              items={lookupTypes.modeOfContacts}
              getItemId={(x) => x.modeOfContactId.toString()}
              getItemValue={(x) => x.modeOfContactDescription}
              setSelectedItem={builder.setModeOfContact}
              selectedItem={builder.modeOfContact}
              label="Mode of consultation"
              className="w-full"
            />
          </div>
        </div>
        <div className="col-span-8 col-start-3 lg:col-span-4 lg:col-start-9">
          <Dropdown
            items={lookupTypes.consultationTypes}
            getItemId={(x) => x.consultationTypeId.toString()}
            getItemValue={(x) => x.consultationTypeDescription}
            setSelectedItem={builder.setConsultationType}
            selectedItem={builder.consultationType}
            label="Consultation type"
            className="w-full"
          />
        </div>
      </div>
      {builder.didNotAttend || (
        <>
          <div className="col-span-12 col-start-1">
            <div className="flex flex-row items-center justify-center">
              <p className="mx-32 mt-8 text-center text-subtitle">
                A reminder that a successful FCP service is to reduce GP
                workload and to only refer patients on to other clinical
                services or investigations when clinically indicated.
              </p>
            </div>
          </div>

          <h1 className="mt-8 text-2xl text-center">
            Primary consultation outcomes
          </h1>
          <h3 className="mb-4 text-lg italic text-center text-subtitle">
            (select up to 3 outcomes)
          </h3>
          <div className="w-[600px] mx-auto">
            <CheckboxList
              items={lookupTypes.consultationOutcomes}
              selectedItems={builder.consultationOutcomes}
              setSelectedItems={builder.setConsultationOutcomes}
              setOtherText={builder.setCustomConsultationOutcome}
              otherText={builder.customConsultationOutcome}
            />
          </div>
        </>
      )}
      <div className="w-[600px] mx-auto">
        {validationErrors.length > 0 && (
          <div className="mt-4 text-red-500">
            <p className="font-semibold text-center">
              Please address the following feedback before continuing:
            </p>
            <ul className="px-20 ml-24 list-disc">
              {validationErrors.map((x) => (
                <li key={x}>{x}</li>
              ))}
            </ul>
          </div>
        )}
        <div className="flex flex-row justify-center mt-12">
          <Button text="Review" variant="filled" onClick={onReviewClick} />
        </div>
      </div>
    </div>
  );
}
