import { IMetadata } from '../../interfaces/common';
import { getApiCached } from '../../utils/api';

// api/Lookup/ReferralSources
export interface IReferralSource extends IMetadata {
  referralSourceId: number;
  referralSourceDescription: string;
}

export const getReferralSources = () =>
  getApiCached<IReferralSource[]>('lookup/referralsources');
