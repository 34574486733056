import { useMsal } from '@azure/msal-react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export interface IForbidAuthProps {
  children: React.ReactNode;
}

export function ForbidAuth({ children }: IForbidAuthProps) {
  const { instance } = useMsal();
  const user = instance.getActiveAccount();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user) {
      navigate('/gateway');
    }
  }, [user]);

  return <div>{!user && children}</div>;
}
