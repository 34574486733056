import { useMsal } from '@azure/msal-react';
import React from 'react';
import { logout } from '../../utils/security';
import HeaderNavMenu from './HeaderNavMenu';

function Header() {
  const { instance } = useMsal();

  return (
    <div className="w-full sticky z-30 top-0 flex flex-row items-center justify-around h-32 bg-primary-500 px-[10%] md:px-0 shadow-sm">
      <div className="flex flex-row items-center">
        <img src="/logo.png" alt="Physio App Logo" />
      </div>
      <div className="flex flex-row items-center justify-center pr-4 md:pr-0">
        <div className="pr-32 w-[50vw]">
          <HeaderNavMenu />
        </div>
        <div className="flex flex-col font-semibold text-right text-white ">
          <div className="mb-2 text-xl">
            {instance.getActiveAccount()?.name}
          </div>
          <div
            onClickCapture={() => logout()}
            className="font-bold cursor-pointer hover:text-gray-300"
          >
            Logout
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
