import * as React from 'react';

export interface IButtonProps {
  onClick?: () => void;
  variant: 'outline' | 'filled';
  text: string;
  className?: string;
}

export function Button({ onClick, variant, text, className }: IButtonProps) {
  return (
    <span
      onClickCapture={onClick}
      className={`px-8 py-2 cursor-pointer rounded-lg select-none text-lg  text-center ${className} ${
        variant === 'outline'
          ? 'border-gray-500 border-2 text-subtitle font-semibold hover:bg-white hover:border-primary-500 hover:text-primary-500'
          : 'bg-primary-500 hover:bg-primary-600 hover:border-primary-600 text-white font-semibold border-2 border-primary-500'
      } `}
    >
      {text}
    </span>
  );
}
