import { useMemo } from 'react';
import useApiResult from './common/useApiResult';
import { getGeneralPractices } from './promises/getGeneralPractices';

function useGetGeneralPractices(query: string, pcnId: number) {
  const generalPracticesResult = useMemo(
    () => getGeneralPractices(query, pcnId),
    [query, pcnId]
  );
  const { loading, error, result } = useApiResult(generalPracticesResult);

  return { loading, error, practices: result };
}

export default useGetGeneralPractices;
