import AppointmentOverview from '../../components/AppointmentOverview/AppointmentOverview';

import { Button } from '../../components/Button/Button';
import { ICreateAppointmentBuilder } from '../../hooks/useCreateAppointmentRequest';

interface IRegisterAppointmentReviewProps {
  builder: ICreateAppointmentBuilder;
  onEditClick: () => void;
  onSubmitClick: () => void;
}

export default function RegisterAppointmentReview({
  builder,
  onEditClick,
  onSubmitClick,
}: IRegisterAppointmentReviewProps) {
  return (
    <div>
      <p className="mt-8 text-center text-subtitle">
        Ensure that the information below has been entered correctly.
      </p>
      <AppointmentOverview
        fcpName={builder.fcpName}
        didNotAttend={builder.didNotAttend}
        dateOfConsultation={builder.dateOfConsultation}
        doubleAppointment={builder.doubleAppointment}
        organisation={builder.organisation?.organisationDescription ?? ''}
        pcnName={builder.pcnName}
        icsCcgName={builder.icsCcgName}
        referralSource={builder.referralSource?.referralSourceDescription ?? ''}
        modeOfContact={builder.modeOfContact?.modeOfContactDescription ?? ''}
        consultationType={
          builder.consultationType?.consultationTypeDescription ?? ''
        }
        consultationOutcomes={builder.consultationOutcomes.map((x) =>
          x.consultationOutcomeId ===
          Number(process.env.REACT_APP_OUTCOME_OTHER_BOX_ID)
            ? builder.customConsultationOutcome
            : x.consultationOutcomeDescription
        )}
        customConsultationOutcome={builder.customConsultationOutcome}
      />
      <div className="flex justify-between mx-auto mt-8 w-96">
        <Button text="Edit" variant="outline" onClick={onEditClick} />
        <Button text="Submit" variant="filled" onClick={onSubmitClick} />
      </div>
    </div>
  );
}
