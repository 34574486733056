import * as React from 'react';

import Spinner from '../../components/Spinner/Spinner';

export default function RegisterAppointmentSending() {
  return (
    <div className="flex justify-center mx-auto mt-32">
      <div className="flex flex-col items-center">
        <Spinner />
        <h1 className="mt-8 text-2xl text-center text-subtitle">
          Submitting new appointment...
        </h1>
      </div>
    </div>
  );
}
