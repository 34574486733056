import React, { useState } from 'react';
import TimeAgo from 'javascript-time-ago';
import moment from 'moment';
import en from 'javascript-time-ago/locale/en';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import Layout from '../components/Layout';
import { Title } from '../components/Title';
import useGetAppointments from '../hooks/useGetAppointments';
import AppointmentOverview from '../components/AppointmentOverview/AppointmentOverview';
import { Button } from '../components/Button/Button';
import { IAppointment } from '../hooks/promises/getAppointments';
import LoadingPage from './LoadingPage';
import ErrorPage from './ErrorPage';
import { RequireAuth } from '../components/AuthWrapper/RequireAuth';
import setTitle from '../utils/title';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-GB');

const toastOptions: ToastOptions = {
  position: 'bottom-right',
};

function toLocalTime(d: string) {
  const utcDate = moment.utc(d);
  const localDate = moment(utcDate).local();
  const now = new Date();

  return localDate.toDate() > now ? now : localDate.toDate();
}

interface IAppointmentViewWrapperProps {
  appointment: IAppointment;
}

function AppointmentViewWrapper({ appointment }: IAppointmentViewWrapperProps) {
  return (
    <AppointmentOverview
      fcpName={`${appointment.user.forename} ${appointment.user.surname}`}
      didNotAttend={appointment.didNotAttendFlag}
      dateOfConsultation={new Date(appointment.consultationDate)}
      doubleAppointment={appointment.doubleAppointmentFlag}
      organisation={appointment.organisation.organisationDescription}
      pcnName={
        appointment.organisation.primaryCareNetwork.organisationDescription
      }
      icsCcgName={
        appointment.organisation.clinicalCommissioningGroup
          .organisationDescription
      }
      referralSource={appointment.referralSourceDescription}
      modeOfContact={appointment.modeOfContactDescription}
      consultationType={appointment.consultationTypeDescription}
      consultationOutcomes={appointment.appointmentConsultationOutcomeList.map(
        (y) =>
          y.consultationOutcomeId ===
          Number(process.env.REACT_APP_OUTCOME_OTHER_BOX_ID)
            ? y.otherText
            : y.consultationOutcomeDescription
      )}
      customConsultationOutcome="CUSTOM CONSULTATION OUTCOME"
    />
  );
}

function ViewAppointmentsPageUnsecured() {
  const { appointments, loading, error, removeAppointment } =
    useGetAppointments();
  const [showModal, setShowModal] = useState(false);
  const [appointmentSelectedForDeletion, setAppointmentSelectedForDeletion] =
    useState<IAppointment | null>(null);
  const navigate = useNavigate();

  const deleteAppointment = () => {
    if (!appointmentSelectedForDeletion) return;

    removeAppointment(appointmentSelectedForDeletion.appointmentId)
      .then(() => {
        // remove from appointments list
        toast.success('Succesfully delete appointment', toastOptions);
        setTimeout(() => navigate(0), 1000);
      })
      .catch(() => {
        toast.error('Failed to delete appointment', toastOptions);
      });
  };

  if (loading) return <LoadingPage layout />;

  if (error) return <ErrorPage layout />;

  if (appointments?.length === 0) {
    return (
      <Layout>
        <Title text="View appointments" />
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          fontSize={96}
          className="flex mx-auto mt-32 text-subtitle"
        />
        <h3 className="mt-8 text-2xl text-center text-subtitle">
          There are no existing appointments to view.
        </h3>
      </Layout>
    );
  }

  return (
    <Layout>
      <Title text="View appointments" />
      <p className="mb-6 text-center text-subtitle">
        Showing 20 most recently created appointments.
      </p>
      <div className="grid grid-cols-2 gap-4 mb-8">
        {appointments?.map((x) => (
          <div
            className="relative bg-white rounded-md cols-span-1 shadow-md border-[1px] pb-16"
            key={x.appointmentId}
          >
            <div className="flex flex-col justify-between p-4">
              <AppointmentViewWrapper key={x.appointmentId} appointment={x} />
              <div className="absolute bottom-0 right-0 flex items-center px-4 py-4">
                <p className="mr-6 text-subtitle">
                  Created {timeAgo.format(toLocalTime(x.dateCreated))}
                </p>
                <Button
                  variant="filled"
                  text="Delete"
                  onClick={() => {
                    setAppointmentSelectedForDeletion(x);
                    setShowModal(true);
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        <div
          className={`${
            showModal ? 'visible' : 'invisible'
          } fixed top-0 bottom-0 left-0 right-0 z-40 h-full bg-black opacity-50`}
          onClickCapture={() => setShowModal(false)}
        />
        <div
          className={`${
            showModal ? 'visible' : 'invisible'
          } fixed top-0 bottom-0 left-0 right-0 z-50 m-auto w-[700px] h-[800px] bg-white p-8 rounded-lg shadow-lg`}
        >
          <div className="relative">
            <h1 className="text-2xl font-semibold text-center">
              Delete appointment record?
            </h1>
            <p className="mt-2 text-center text-subtitle">
              This action is irreversible.
            </p>
            {appointmentSelectedForDeletion && (
              <AppointmentViewWrapper
                appointment={appointmentSelectedForDeletion}
              />
            )}
          </div>
          <div className="absolute bottom-0 left-0 right-0">
            <div className="flex flex-row justify-around my-16">
              <Button
                variant="outline"
                text="Keep it"
                onClick={() => setShowModal(false)}
              />

              <Button
                variant="filled"
                text="Delete it"
                onClick={deleteAppointment}
              />
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </Layout>
  );
}

function ViewAppointmentsPage() {
  setTitle('View Appointments');
  return (
    <RequireAuth>
      <ViewAppointmentsPageUnsecured />
    </RequireAuth>
  );
}

export default ViewAppointmentsPage;
