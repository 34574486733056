import { IMetadata } from '../../interfaces/common';
import { getApiCached } from '../../utils/api';

// api/Lookup/ModeOfContacts
export interface IModeOfContact extends IMetadata {
  modeOfContactId: number;
  modeOfContactDescription: string;
}

export const getModeOfContacts = () =>
  getApiCached<IModeOfContact[]>('lookup/modeofcontacts');
