import * as React from 'react';
import { RequireAuth } from '../../components/AuthWrapper/RequireAuth';
import Layout from '../../components/Layout';
import { Title } from '../../components/Title';
import { useCreateAppointmentRequest } from '../../hooks/useCreateAppointmentRequest';
import setTitle from '../../utils/title';
import RegisterAppointmentForm from './RegisterAppointmentForm';
import RegisterAppointmentReview from './RegisterAppointmentReview';
import RegisterAppointmentSending from './RegisterAppointmentSending';
import RegisterAppointmentSent from './RegisterAppointmentSent';

enum RegisterAppointmentStage {
  DataEntry,
  Review,
  Submitting,
  Submitted,
}

function AppointmentStageRenderer() {
  const { builder, validateRequest, createRequest, createAppointment } =
    useCreateAppointmentRequest();
  const [stage, setStage] = React.useState<RegisterAppointmentStage>(
    RegisterAppointmentStage.DataEntry
  );

  const onDataEntrySuccess = () => {
    setStage(RegisterAppointmentStage.Review);
  };

  const onReviewEditClick = () => setStage(RegisterAppointmentStage.DataEntry);
  const onReviewSubmitClick = () => {
    setStage(RegisterAppointmentStage.Submitting);
    const request = createRequest();
    console.log(request);
    if (!request) throw new Error('Create Appointment request is malformed');
    createAppointment(request)
      .then(() => setStage(RegisterAppointmentStage.Submitted))
      .catch((e) => console.error(e));
  };

  switch (stage) {
    case RegisterAppointmentStage.DataEntry:
      return (
        <RegisterAppointmentForm
          builder={builder}
          validateRequest={validateRequest}
          onValidateSuccess={onDataEntrySuccess}
        />
      );
    case RegisterAppointmentStage.Review:
      return (
        <RegisterAppointmentReview
          builder={builder}
          onEditClick={onReviewEditClick}
          onSubmitClick={onReviewSubmitClick}
        />
      );
    case RegisterAppointmentStage.Submitting:
      return <RegisterAppointmentSending />;
    case RegisterAppointmentStage.Submitted:
      return <RegisterAppointmentSent />;
    default:
      throw new Error('Unexpected stage encountered');
  }
}

function RegisterAppointment() {
  setTitle('Register Appointment');
  return (
    <RequireAuth>
      <Layout>
        <div className="mb-4">
          <Title text="Register a new appointment" />
        </div>
        <AppointmentStageRenderer />
      </Layout>
    </RequireAuth>
  );
}

export default RegisterAppointment;
