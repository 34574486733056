import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Layout from '../components/Layout';

interface IErrorPageProps {
  layout?: boolean;
  message?: string;
}

export default function ErrorPage({ layout, message }: IErrorPageProps) {
  const page = (
    <div className="mt-64 text-center">
      <div className="text-center text-subtitle">
        <FontAwesomeIcon icon={faWarning} fontSize={96} />
      </div>
      <div className="mt-4">
        <p className="text-lg font-semibold text-subtitle">
          {message ?? 'There was an error communicating with the server.'}
        </p>
      </div>
    </div>
  );

  if (layout) return <Layout>{page}</Layout>;

  return page;
}
