/* eslint-disable indent */
import { useState } from 'react';
import { postApi } from '../utils/api';
import { IConsultationOutcome } from './promises/getConsultationOutcomes';
import { IConsultationType } from './promises/getConsultationTypes';
import { IGeneralPractice } from './promises/getGeneralPractices';
import { IModeOfContact } from './promises/getModeOfContacts';
import { IReferralSource } from './promises/getReferralSources';

export interface ICreateAppointmentRequestValidationResult {
  validationMessages: string[];
  success: boolean;
}

type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>;

interface IAppointmentConsultationOutcomeRequest {
  consultationOutcomeId: number;
  otherText?: string;
}

interface ICreateAppointmentRequest {
  organisationId: number;
  consultationDate: string;
  referralSourceId: number;
  modeOfContactId: number;
  consultationTypeId: number;
  doubleAppointmentFlag: boolean;
  didNotAttendFlag: boolean;
  deletedFlag: boolean;
  appointmentConsultationOutcomeList: IAppointmentConsultationOutcomeRequest[];
}

export interface ICreateAppointmentBuilder {
  setDateOfConsultation: StateSetter<Date | null>;
  setFcpName: StateSetter<string>;
  setDidNotAttend: StateSetter<boolean>;
  setDoubleAppointment: StateSetter<boolean>;
  setOrganisation: StateSetter<IGeneralPractice | null>;
  setPcnName: StateSetter<string>;
  setIcsCcgName: StateSetter<string>;
  setReferralSource: StateSetter<IReferralSource | null>;
  setModeOfContact: StateSetter<IModeOfContact | null>;
  setConsultationType: StateSetter<IConsultationType | null>;
  setConsultationOutcomes: StateSetter<IConsultationOutcome[]>;
  setCustomConsultationOutcome: StateSetter<string>;
  dateOfConsultation: Date | null;
  fcpName: string;
  didNotAttend: boolean;
  doubleAppointment: boolean;
  organisation: IGeneralPractice | null;
  pcnName: string;
  icsCcgName: string;
  referralSource: IReferralSource | null;
  modeOfContact: IModeOfContact | null;
  consultationType: IConsultationType | null;
  consultationOutcomes: IConsultationOutcome[];
  customConsultationOutcome: string;
}

export function useCreateAppointmentRequest() {
  const [dateOfConsultation, setDateOfConsultation] = useState<Date | null>(
    new Date()
  );
  const [fcpName, setFcpName] = useState('');
  const [didNotAttend, setDidNotAttend] = useState(false);
  const [doubleAppointment, setDoubleAppointment] = useState(false);
  const [organisation, setOrganisation] = useState<IGeneralPractice | null>(
    null
  );
  const [pcnName, setPcnName] = useState('');
  const [icsCcgName, setIcsCcgName] = useState('');
  const [referralSource, setReferralSource] = useState<IReferralSource | null>(
    null
  );
  const [modeOfContact, setModeOfContact] = useState<IModeOfContact | null>(
    null
  );
  const [consultationType, setConsultationType] =
    useState<IConsultationType | null>(null);
  const [consultationOutcomes, setConsultationOutcomes] = useState<
    IConsultationOutcome[]
  >([]);
  const [customConsultationOutcome, setCustomConsultationOutcome] =
    useState<string>('');

  const builder: ICreateAppointmentBuilder = {
    setDateOfConsultation,
    setFcpName,
    setDidNotAttend,
    setDoubleAppointment,
    setOrganisation,
    setPcnName,
    setIcsCcgName,
    setReferralSource,
    setModeOfContact,
    setConsultationType,
    setConsultationOutcomes,
    setCustomConsultationOutcome,
    dateOfConsultation,
    fcpName,
    didNotAttend,
    doubleAppointment,
    organisation,
    pcnName,
    icsCcgName,
    referralSource,
    modeOfContact,
    consultationType,
    consultationOutcomes,
    customConsultationOutcome,
  };

  const validateRequest = (): ICreateAppointmentRequestValidationResult => {
    const validationErrors: string[] = [];

    const addError = (name: string) =>
      validationErrors.push(`${name} must be provided.`);

    if (dateOfConsultation === null) addError('A date of consultation');
    if (!fcpName) addError('An FCP name');
    if (!organisation) addError('A GP practice');
    if (!pcnName) addError('A PCN name');
    if (!icsCcgName) addError('An ICB name');
    if (!referralSource) addError('A referral source');
    if (!modeOfContact) addError('A mode of contact');
    if (!consultationType) addError('A consultation type');
    if (consultationOutcomes.length === 0 && !didNotAttend)
      addError('A consultation outcome');
    if (
      consultationOutcomes.filter((x) => x.consultationOutcomeId === 24)
        .length > 0 &&
      !customConsultationOutcome
    )
      addError('If custom outcome is checked, a reason');

    return {
      success: validationErrors.length === 0,
      validationMessages: validationErrors,
    };
  };

  const createRequest = (): ICreateAppointmentRequest | null => {
    if (dateOfConsultation === null) return null;
    if (!organisation) return null;
    if (!fcpName) return null;
    if (!pcnName) return null;
    if (!icsCcgName) return null;
    if (!referralSource) return null;
    if (!modeOfContact) return null;
    if (!consultationType) return null;

    return {
      organisationId: organisation.organisationId,
      consultationDate: dateOfConsultation.toISOString(),
      referralSourceId: referralSource.referralSourceId,
      modeOfContactId: modeOfContact.modeOfContactId,
      consultationTypeId: consultationType.consultationTypeId,
      doubleAppointmentFlag: doubleAppointment,
      deletedFlag: false,
      didNotAttendFlag: didNotAttend,
      appointmentConsultationOutcomeList: didNotAttend
        ? []
        : consultationOutcomes.map((x) => ({
            consultationOutcomeId: x.consultationOutcomeId,
            otherText:
              x.consultationOutcomeId ===
              Number(process.env.REACT_APP_OUTCOME_OTHER_BOX_ID)
                ? customConsultationOutcome
                : '',
          })),
    };
  };

  function createAppointment(request: ICreateAppointmentRequest) {
    return postApi<unknown, ICreateAppointmentRequest>('Appointments', request);
  }

  return { builder, validateRequest, createRequest, createAppointment };
}
