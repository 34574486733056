import { deleteApi, getApi, postApi } from '../../utils/api';
import { IGeneralPractice } from './getGeneralPractices';

export interface IFavourite {
  organisationId: number;
}

export interface IGetFavourite {
  userId: number;
  organisation: IGeneralPractice;
  createdDate: Date;
}

export const getFavourites = () => getApi<IGetFavourite[]>('Favourites?');

export const postFavourite = (organisationId: number) =>
  postApi<IFavourite, IFavourite>('Favourites', {
    organisationId,
  });

export const deleteFavourite = (organisationId: number) =>
  deleteApi<IFavourite>(`Favourites/${organisationId}`);
