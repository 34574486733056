import fileSaver from 'file-saver';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RequireAuth } from '../components/AuthWrapper/RequireAuth';
import { Button } from '../components/Button/Button';
import { DateField } from '../components/DateField/DateField';
import Layout from '../components/Layout';
import { Title } from '../components/Title';
import { axiosApiInstance } from '../utils/api';
import { UserRole } from '../utils/security';
import setTitle from '../utils/title';

function ReportingPage() {
  setTitle('Reporting');
  const [toDate, setToDate] = useState<Date>(new Date());
  const [fromDate, setFromDate] = useState<Date>(new Date());

  async function downloadFile(url: string, request: string) {
    axiosApiInstance({
      url,
      method: 'GET',
      data: request,
      responseType: 'blob',
    })
      .then((response) => {
        fileSaver.saveAs(new Blob([response.data]), request);
        return true;
      })
      .catch((error) => {
        console.error('Failed', error);
        console.error('Failed', error);
      });
  }

  useEffect(() => {
    if (toDate < fromDate) setToDate(fromDate);
  }, [toDate, fromDate]);

  return (
    <RequireAuth requireRole={[UserRole.PhysioReporter]}>
      <Layout>
        <div className="flex flex-col items-center justify-center w-full">
          <Title text="Reporting" />
          From
          <DateField
            className="m-4 text-xl font-semibold text-center"
            date={fromDate}
            setDate={setFromDate}
          />
          To
          <DateField
            minDate={fromDate}
            className="m-4 text-xl font-semibold text-center"
            date={toDate}
            setDate={setToDate}
          />
          <Button
            onClick={() =>
              downloadFile(
                `${
                  process.env.REACT_APP_API_URL
                }Reports/ReportDump?&fromDate=${moment(fromDate).format(
                  'YYYY-MM-DD'
                )}&toDate=${moment(toDate).format('YYYY-MM-DD')}`,
                `PhysioReportDump-${moment(fromDate).format(
                  'YYYY-MM-DD'
                )}-${moment(toDate).format(
                  'YYYY-MM-DD'
                )}-Generated-${moment().format('DD-MM-YYYY hh:mm:ss')}.csv`
              )
            }
            className="m-4"
            variant="filled"
            text="Download CSV"
          />
        </div>
      </Layout>
    </RequireAuth>
  );
}

export default ReportingPage;
