import { Select } from '@mantine/core';
import { useEffect, useState } from 'react';

interface SelectItem {
  value: string;
  label: string;
}
export interface IDropdownSearchProps<T> {
  className?: string;
  defaultValue?: T;
  label?: string;
  items: T[];

  searchable?: boolean;

  searchQuery?: string;
  onSearch?: (query: string) => void;

  selectedItem: T | null;
  setSelectedItem: (item: T | null) => void;

  getItemId: (item: T) => string;
  getItemValue: (item: T) => string;
}

export function Dropdown<T>({
  className,
  searchQuery,
  searchable,
  onSearch,
  defaultValue,
  label,
  items,
  selectedItem,
  setSelectedItem,
  getItemId,
  getItemValue,
}: IDropdownSearchProps<T>) {
  const [mappedData, setMappedData] = useState<SelectItem[]>([]);
  const [mappedSelectedItem, setMappedSelectedItem] = useState<string | null>(
    null
  );

  function mapToSelectItem(item: T): SelectItem {
    return {
      value: getItemId(item),
      label: getItemValue(item),
    };
  }

  useEffect(() => {
    setMappedData(items.map(mapToSelectItem));
  }, [items]);

  useEffect(() => {
    if (!selectedItem) {
      setMappedSelectedItem(null);
      return;
    }
    setMappedSelectedItem(getItemId(selectedItem));
  }, [selectedItem]);

  const onChange = (value: string) => {
    const matchingItems = items.filter((x) => getItemId(x) === value);
    if (matchingItems.length === 0) throw new Error('no matching items');
    const item = matchingItems[0];

    setSelectedItem(item);
  };

  return (
    <Select
      placeholder={label}
      data={mappedData}
      className={`w-full ${className}`}
      onChange={onChange}
      defaultValue={defaultValue && getItemId(defaultValue)}
      value={mappedSelectedItem ?? null}
      searchable={searchable}
      searchValue={searchQuery}
      onSearchChange={onSearch}
      size="lg"
    />
  );
}
