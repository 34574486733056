/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import LoadingPage from './LoadingPage';
import ErrorPage from './ErrorPage';
import { getUser } from '../hooks/promises/getUser';
import { retrieveAccessToken, storeAccessToken } from '../utils/security';

function GatewayPage() {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function onSuccessfulLogin(result: AuthenticationResult) {
    storeAccessToken(result.idToken);
    instance.setActiveAccount(result.account);

    try {
      await getUser();
      navigate('/set-capacity');
    } catch (e) {
      setError(true);
      throw e;
    }
  }

  useEffect(() => {
    const callbackId = instance.addEventCallback(async (message: any) => {
      console.log(message);
      if (
        message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        message.eventType === EventType.LOGIN_SUCCESS
      ) {
        const result = message.payload as AuthenticationResult;
        await onSuccessfulLogin(result);
      }

      if (
        message.eventType === EventType.LOGIN_FAILURE ||
        message.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
        message.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE ||
        message.eventType === EventType.SSO_SILENT_FAILURE
      ) {
        setError(true);
        setErrorMessage(message.error.errorMessage);
        localStorage.clear();
      }
    });

    retrieveAccessToken().then((response) => {
      if (!response) return;
      navigate('/set-capacity');
      console.log('already logged in');
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  if (error) {
    return <ErrorPage message={errorMessage} />;
  }

  return <LoadingPage />;
}

export default GatewayPage;
