import React, { useState } from 'react';
import moment from 'moment';
import { Navigate } from 'react-router-dom';
import { DateField } from '../components/DateField/DateField';
import Layout from '../components/Layout';
import { Title } from '../components/Title';
import useDailyCapacity from '../hooks/common/useDailyCapacity';
import TextField from '../components/TextField/TextField';
import { Button } from '../components/Button/Button';
import LoadingPage from './LoadingPage';
import ErrorPage from './ErrorPage';
import { RequireAuth } from '../components/AuthWrapper/RequireAuth';
import setTitle from '../utils/title';

export enum Mode {
  edit = 'edit',
  initial = 'initial',
}

interface ISetCapacityPageProps {
  mode: Mode;
}

function SetCapacityPageUnsecured({ mode }: ISetCapacityPageProps) {
  const today = moment().format('YYYY-MM-DD');

  const [validated, setValidated] = useState<boolean | null>(null);
  const [date, setDate] = useState<Date>(new Date());

  const { loading, error, dailyCapacity, addDailyCapacity } = useDailyCapacity(
    moment(date).format('YYYY-MM-DD')
  );

  const [capacityValue, setCapacityValue] = useState<string>('');

  const validateNumberInput = () => /^\d+$/.test(capacityValue);

  const onBtnClick = () => {
    if (!validateNumberInput()) {
      setValidated(false);
      return;
    }
    setValidated(true);
    addDailyCapacity(Number(capacityValue));
  };

  if (loading) return <LoadingPage layout />;

  if (error) return <ErrorPage layout />;

  const page = (
    <Layout>
      <Title text="Welcome" />
      <div className="flex flex-col justify-center mt-16">
        <Title text="How many appointment slots do you have today?" />
        <p className="italic text-center">(Or pick a historic date)</p>
        <div className="flex justify-center w-full">
          <DateField
            maxDate={moment().toDate()}
            className="text-xl font-semibold text-center"
            date={date}
            setDate={setDate}
          />
        </div>
      </div>
      <div className="flex justify-center w-full mt-5">
        <TextField
          className="w-16"
          text={capacityValue}
          setText={setCapacityValue}
          placeholderText={dailyCapacity?.capacityCount.toString() ?? '0'}
          pattern="^[0-9]*$"
          size="xl"
          textAlign="center"
        />
      </div>
      {validated === false ? (
        <p className="p-1 text-center text-red-600">Please enter a number.</p>
      ) : (
        ''
      )}
      <div className="flex justify-center m-5">
        <Button
          className="p-4 m-5 font-semibold text-white bg-blue-500 border border-gray-400 rounded-md"
          onClick={onBtnClick}
          text="Confirm Slots"
          variant="filled"
        />
      </div>
      {validated === true ? (
        <p className="font-semibold text-center text-green-500">Submitted</p>
      ) : null}
    </Layout>
  );

  if (dailyCapacity?.capacityDate.split('T')[0] === today) {
    if (mode === Mode.edit) {
      return page;
    }
    return <Navigate to="/register" replace />;
  }

  return validated ? <Navigate to="/register" replace /> : page;
}

function SetCapacityPage({ mode }: ISetCapacityPageProps) {
  setTitle(mode === Mode.edit ? 'Edit Capacity' : 'Set Capacity');
  return (
    <RequireAuth>
      <SetCapacityPageUnsecured mode={mode} />
    </RequireAuth>
  );
}

export default SetCapacityPage;
