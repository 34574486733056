import { useMemo } from 'react';
import useApiResult from './common/useApiResult';
import { deleteAppointment, getAppointments } from './promises/getAppointments';

function useGetAppointments() {
  const appointmentsResult = useMemo(() => getAppointments(), []);
  const { loading, error, result } = useApiResult(appointmentsResult);

  const removeAppointment = (appointmentId: number) =>
    deleteAppointment(appointmentId);

  return { loading, error, appointments: result, removeAppointment };
}

export default useGetAppointments;
