import { deleteApi, getApi } from '../../utils/api';
import { IGeneralPractice } from './getGeneralPractices';

export interface IAppointmentOutcome {
  appointmentId: number;
  consultationOutcomeId: number;
  otherText: string;
  consultationOutcomeDescription: string;
}

interface IUser {
  forename: string;
  surname: string;
  azureUserName: string;
  createdDate: Date;
}

export interface IAppointment {
  user: IUser;
  organisation: IGeneralPractice;
  consultationDate: string;
  referralSourceId: number;
  modeOfContactId: number;
  consultationTypeId: number;
  dateCreated: string;
  appointmentConsultationOutcomeList: IAppointmentOutcome[];
  doubleAppointmentFlag: boolean;
  didNotAttendFlag: boolean;

  appointmentId: number;
  referralSourceDescription: string;
  modeOfContactDescription: string;
  consultationTypeDescription: string;
}

export const getAppointments = () => getApi<IAppointment[]>('appointments');

export const deleteAppointment = (appointmentId: number) =>
  deleteApi<unknown>(`appointments/${appointmentId}`);
