import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FavouritesPage from './pages/FavouritesPage';
import LoginPage from './pages/LoginPage';
import RegisterAppointment from './pages/RegisterAppointment/RegisterAppointment';
import SetCapacityPage, { Mode } from './pages/SetCapacityPage';
import GatewayPage from './pages/GatewayPage';
import ViewAppointmentsPage from './pages/ViewAppointments';
import ReportingPage from './pages/ReportingPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterAppointment />} />
        <Route path="/view" element={<ViewAppointmentsPage />} />
        <Route path="/favourites" element={<FavouritesPage />} />
        <Route
          path="/set-capacity"
          element={<SetCapacityPage mode={Mode.initial} />}
        />
        <Route
          path="/edit-capacity"
          element={<SetCapacityPage mode={Mode.edit} />}
        />
        <Route path="/gateway" element={<GatewayPage />} />
        <Route path="/reporting" element={<ReportingPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
