interface IAppointmentOverviewRowProps {
  field: string;
  value: string | string[];
}

function AppointmentOverviewRow({
  field,
  value,
}: IAppointmentOverviewRowProps) {
  return (
    <>
      <div className="col-span-1 text-lg text-right text-subtitle">
        {field}:
      </div>
      <div className="col-span-1 text-lg font-semibold">
        {typeof value === 'string'
          ? value
          : value.map((x) => <li key={x}>{x}</li>)}
      </div>
    </>
  );
}

interface IAppointmentOverviewProps {
  fcpName: string;
  didNotAttend: boolean;
  doubleAppointment: boolean | null;
  dateOfConsultation: Date | null;
  organisation: string;
  pcnName: string;
  icsCcgName: string;
  referralSource: string;
  modeOfContact: string;
  consultationType: string;
  consultationOutcomes: string[];
  customConsultationOutcome: string;
}

export default function IAppointmentOverview({
  fcpName,
  didNotAttend,
  doubleAppointment,
  dateOfConsultation,
  organisation,
  pcnName,
  icsCcgName,
  referralSource,
  modeOfContact,
  consultationType,
  consultationOutcomes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customConsultationOutcome,
}: IAppointmentOverviewProps) {
  window.addEventListener('popstate', (e) => {
    e.preventDefault();
  });
  return (
    <div className="grid grid-cols-2 gap-2 mt-8">
      <AppointmentOverviewRow field="FCP Name" value={fcpName} />
      <AppointmentOverviewRow
        field="Attendance status"
        value={didNotAttend ? 'Did not attend' : 'Attended'}
      />
      <AppointmentOverviewRow
        field="Appointment Type"
        value={doubleAppointment ? 'Double' : 'Single'}
      />
      <AppointmentOverviewRow
        field="Date of consultation"
        value={dateOfConsultation?.toLocaleDateString('en-GB') ?? ''}
      />
      <AppointmentOverviewRow
        field="GP Practice referred from"
        value={organisation}
      />
      <AppointmentOverviewRow field="PCN Name" value={pcnName} />
      <AppointmentOverviewRow field="ICB Name" value={icsCcgName ?? ''} />
      <AppointmentOverviewRow field="Referral Source" value={referralSource} />
      <AppointmentOverviewRow
        field="Mode of Consultation"
        value={modeOfContact}
      />
      <AppointmentOverviewRow
        field="Consultation Type"
        value={consultationType}
      />
      {didNotAttend || (
        <AppointmentOverviewRow
          field="Consultation Outcome(s)"
          value={consultationOutcomes}
        />
      )}
    </div>
  );
}
